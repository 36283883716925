import React from "react";
import { formatTextWithLineBreaks } from '../utils/helper';
export const Team = (props) => {


  return (
    <div id="team">
      <div className="container">
          <div className="row justify-content-center">
              <div className="col-12">
                  <div className="about-text header-section">
                      <h2>{props.data ? props.data.title : "loading..."}</h2>
                  </div>
                  <div className="team-grid">
                      <ul>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/javi.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/maxi.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/guille.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>

                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/nico.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/migue.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/michael.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/fede.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/gime.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/flor.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/martin.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/eze.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/ivan.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/juli.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/flor2.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>
                          <li>
                              <div className="profile">
                                  <img src="../img/profiles/cin.jpg"/>
                              </div>
                              <h3>Nombre Apellido</h3>
                              <h4>Puesto</h4>
                          </li>

                      </ul>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
};
