import React from "react";
import {formatTextWithLineBreaks} from "../utils/helper";

export const Footer = (props) => {

  return (
      <div id="footer" className="align-content-end">
          <div className="container-fluid">
              <div className="row">
                  <div
                      className="d-flex">
                      <li>
                          {props.data && (
                              <>
                                  <img src={props.data.logo} className="logo" alt="Logo"/>
                              </>
                          )}
                      </li>
                      <li>
                          {props.data && (
                              <>
                                  {formatTextWithLineBreaks(props.data.text)}
                              </>
                          )}
                      </li>
                  </div>

              </div>
          </div>
      </div>
  );
};
