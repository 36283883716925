import React from "react";
import { formatTextWithLineBreaks } from '../utils/helper';
export const Powered = (props) => {


  return (
    <div id="powered">
      <div className="container">
          <div className="row">
              <div className="col-6 text-end align-content-center">
                  {props.data ? props.data.text : "loading..."}
              </div>
              <div className="col-6">
                  <img src="../img/powered.png" alt="Powered" className="powered-img"/>
              </div>
          </div>
      </div>
    </div>
  );
};
