import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Footer } from "./components/footer";
import LanguageSelector from "./components/LanguageSelector";
import { Games } from "./components/games";
import { Powered } from "./components/powered";
import { Contact } from "./components/contact";
import { useRoutes } from 'react-router-dom';
import { Team } from "./components/team";
import { Legal } from "./components/legal";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

const App = () => {
    const [landingPageData, setLandingPageData] = useState({});
    const [language, setLanguage] = useState('data_en.json'); // Set default language
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        fetchDataJson();
    }, []);

    useEffect(() => {
        fetchLanguageData(language);
    }, [language]);

    const fetchDataJson = async () => {
        try {
            const response = await fetch('/data/data.json'); // Correct path to the public directory
            const data = await response.json();
            setLanguages(data.languages);
        } catch (error) {
            console.error('Error loading data.json:', error);
        }
    };

    const fetchLanguageData = async (file) => {
        try {
            const response = await fetch(`/data/${file}`); // Correct path to the public directory
            const data = await response.json();
            setLandingPageData(data);
        } catch (error) {
            console.error('Error loading language data:', error);
        }
    };

    const handleLanguageChange = (event) => {
        const newLanguage = event.file;
        setLanguage(newLanguage);
    };

    const routes = useRoutes([
        {
            path: "/", element: (
                <>
                    <Header data={landingPageData.Header} />
                    <About data={landingPageData.About} />
                    <Games data={landingPageData.Games} />
                    <Powered data={landingPageData.Powered} />
                    <Contact data={landingPageData.Contact} />
                </>
            )
        },
        { path: "team", element: <Team data={landingPageData.Team} /> },
        { path: "legal", element: <Legal data={landingPageData.Legal} /> }
    ]);

    return (
        <div>
            <Navigation data={landingPageData.Menu}>
                <LanguageSelector onLanguageChange={handleLanguageChange} languages={languages} />
            </Navigation>
            {routes}
            <Footer data={landingPageData.Footer} />
        </div>
    );
};

export default App;
