import React, { useEffect,  useState } from 'react';
export const Navigation = (props) => {

  const [activeLink, setActiveLink] = useState(null);
  const [isNavShown, setIsNavShown] = useState(false);


  useEffect(() => {
  }, []);


  const toggleNav = (e) => {
    if (e.currentTarget.classList.contains('collapsed')) {
      setIsNavShown(false);
    } else {
      setIsNavShown(true);
    }
  };

  const handleClick = (e, href) => {
    e.preventDefault();
    if(href === '#downloads'){
      setActiveLink(null);
    } else {
      setActiveLink(href);
    }
    window.location.href=href;

  };

  return (


      <nav id="menu" className={`fixed-top navbar navbar-default navbar-expand-lg ${isNavShown ? 'with-bg' : ''}`} >

        <div className="container justify-content-end">
          <div className="justify-content-end">
            <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                    onClick={(e) => toggleNav(e)}>
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <a className="logo" href="/#"><img src="../img/logo_header.png"></img></a>
              {props.data ? (
                  props.data.navLinks.map((link, index) => (
                      <li className="nav-item" key={index}>
                        <a
                            className={`nav-link ${link.class} ${activeLink === link.href ? 'active' : ''}`}
                            onClick={(e) => handleClick(e, link.href)}
                            aria-current={activeLink === link.href ? 'page' : undefined}
                        >
                          {link.label}
                        </a>
                      </li>
                  ))
              ) : (
                  "loading..."
              )}
            </ul>

          </div>
          <div className="language">
            {props.children}
          </div>
        </div>
      </nav>
  );
};
