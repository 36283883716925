import React from 'react';

export const Header = (props) => {
  return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="text">
                  {props.data ? (
                      <>
                          {props.data.text}
                      </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
};
