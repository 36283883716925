import React from "react";
import { formatTextWithLineBreaks } from '../utils/helper';
export const Games = (props) => {


  return (
    <div id="games">
      <div className="container">
          <div className="row justify-content-center">
              <div className="col-9">
                  <div className="about-text header-section">
                      <h2>{props.data ? props.data.title : "loading..."}</h2>
                      <div className="cards">
                          <ul>
                              <li>
                                  <div className="viewCard">
                                      <div className="logo"><img src="../img/games/legend.png"></img></div>
                                      <div className="icons">
                                          <img src="../img/windows.png"></img>
                                          <img src="../img/apple.png"></img>
                                          <img src="../img/android.png"></img>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="viewCard">
                                      <div className="logo"><img src="../img/games/gmu.png"></img></div>
                                      <div className="icons">
                                          <img src="../img/windows.png"></img>
                                          <img src="../img/apple.png"></img>
                                          <img src="../img/android.png"></img>
                                      </div>
                                  </div>
                              </li>

                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
};
