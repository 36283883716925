import React from "react";
import { formatTextWithLineBreaks } from '../utils/helper';
export const Contact = (props) => {

  return (
    <div id="contact">
      <div className="container-fluid">
          <div className="row">
              <div className="col-6 map">

              </div>

              <div className="col-6 office align-content-center">
                  <div className="text">
                      <ul>
                          <li>
                              <h1>
                                  {props.data ? props.data.address.title : "loading..."}
                              </h1>
                              <p>{props.data ? props.data.address.text : "loading..."}</p>
                          </li>

                          <li>
                              <h1>
                                  {props.data ? props.data.email.title : "loading..."}
                              </h1>
                              <p>{props.data ? props.data.email.text : "loading..."}</p>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
};
