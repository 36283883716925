import React from "react";
import { formatTextWithLineBreaks } from '../utils/helper';
import { TabView, TabPanel } from 'primereact/tabview';
export const Legal = (props) => {


  return (
    <div id="legal">
      <div className="container">
          <div className="row justify-content-center">
              <div className="col-12">
                  <div className="header-section">
                      <h2>{props.data ? props.data.title : "loading..."}</h2>
                  </div>
                  <div className="legal-data">
                      <TabView>
                          {props.data ? (
                              props.data.tabs.map((item, index) => (
                                  <TabPanel header={item.title} key={index}>
                                      <h3>
                                          {item.title}
                                      </h3>
                                      <div
                                          className="m-0"
                                          dangerouslySetInnerHTML={{__html: item.text}}
                                      />
                                  </TabPanel>
                              ))
                          ) : (
                              "loading..."
                          )}
                      </TabView>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
};
